$icomoon-font-family: "MARSO" !default;
$icomoon-font-path: "fonts" !default;

$marso-icon-document-edit: "\e948";
$marso-icon-flickr: "\e921";
$marso-icon-files: "\e952";
$marso-icon-eye: "\e947";
$marso-icon-DOT: "\e950";
$marso-icon-retread: "\e951";
$marso-icon-wheel_service: "\e94f";
$marso-icon-sq_right: "\e94d";
$marso-icon-sq_wrong: "\e94e";
$marso-icon-minus: "\e949";
$marso-icon-plus1: "\e94a";
$marso-icon-right: "\e94b";
$marso-icon-wrong: "\e94c";
$marso-icon-coll: "\e90c";
$marso-icon-tomlo_nelkul: "\e945";
$marso-icon-forklift: "\e946";
$marso-icon-kart02: "\e902";
$marso-icon-all_season: "\e903";
$marso-icon-filled_truck: "\e904";
$marso-icon-notification: "\e905";
$marso-icon-summer: "\e906";
$marso-icon-winter: "\e907";
$marso-icon-SHOP-kosr: "\e908";
$marso-icon-kle_white: "\e900";
$marso-icon-rim-fringe-protector: "\e901";
$marso-icon-account: "\e909";
$marso-icon-address: "\e90a";
$marso-icon-agri: "\e90b";
$marso-icon-caffee: "\e90d";
$marso-icon-car: "\e90e";
$marso-icon-car_service: "\e90f";
$marso-icon-cargo: "\e910";
$marso-icon-cargo21: "\e911";
$marso-icon-cargo2: "\e912";
$marso-icon-cetelem_01: "\e913";
$marso-icon-cetelem_02: "\e914";
$marso-icon-clock: "\e915";
$marso-icon-creditcard: "\e916";
$marso-icon-customer_Service: "\e917";
$marso-icon-delivery: "\e918";
$marso-icon-discount: "\e919";
$marso-icon-download: "\e91a";
$marso-icon-dump: "\e91b";
$marso-icon-energy_label: "\e91c";
$marso-icon-expert: "\e91d";
$marso-icon-facebook: "\e91e";
$marso-icon-fuel_label: "\e91f";
$marso-icon-gift: "\e920";
$marso-icon-hamburger_menu: "\e922";
$marso-icon-hubcaps-path1: "\e923";
$marso-icon-hubcaps-path2: "\e924";
$marso-icon-hubcaps-path3: "\e925";
$marso-icon-hubcaps-path4: "\e926";
$marso-icon-hubcaps-path5: "\e927";
$marso-icon-hubcaps-path6: "\e928";
$marso-icon-hubcaps-path7: "\e929";
$marso-icon-hubcaps-path8: "\e92a";
$marso-icon-hubcaps-path9: "\e92b";
$marso-icon-hubcaps-path10: "\e92c";
$marso-icon-hubcaps-path11: "\e92d";
$marso-icon-hubcaps-path12: "\e92e";
$marso-icon-hubcaps-path13: "\e92f";
$marso-icon-hubcaps-path14: "\e930";
$marso-icon-hubcaps-path15: "\e931";
$marso-icon-hubcaps-path16: "\e932";
$marso-icon-hubcaps-path17: "\e933";
$marso-icon-hubcaps-path18: "\e934";
$marso-icon-hubcaps-path19: "\e935";
$marso-icon-hubcaps-path20: "\e936";
$marso-icon-hubcaps-path21: "\e937";
$marso-icon-hubcaps-path22: "\e938";
$marso-icon-hubcaps-path23: "\e939";
$marso-icon-hubcaps-path24: "\e93a";
$marso-icon-hubcaps-path25: "\e93b";
$marso-icon-hubcaps-path26: "\e93c";
$marso-icon-info: "\e93d";
$marso-icon-location: "\e93e";
$marso-icon-mail: "\e93f";
$marso-icon-money: "\e940";
$marso-icon-money_transfer: "\e941";
$marso-icon-moto: "\e942";
$marso-icon-news: "\e943";
$marso-icon-noise_label: "\e944";
$marso-icon-on_map: "\e953";
$marso-icon-open: "\e954";
$marso-icon-package: "\e955";
$marso-icon-phone: "\e956";
$marso-icon-promotion_gift: "\e957";
$marso-icon-promotions: "\e958";
$marso-icon-psg: "\e959";
$marso-icon-psg1: "\e95a";
$marso-icon-pumpkin: "\e95b";
$marso-icon-rain_label: "\e95c";
$marso-icon-refresh: "\e95d";
$marso-icon-reinforced: "\e95e";
$marso-icon-runflat: "\e95f";
$marso-icon-search: "\e960";
$marso-icon-service: "\e961";
$marso-icon-shopping_cart: "\e962";
$marso-icon-susp-path1: "\e964";
$marso-icon-susp-path2: "\e965";
$marso-icon-susp-path3: "\e966";
$marso-icon-susp-path4: "\e967";
$marso-icon-susp-path5: "\e968";
$marso-icon-SUV: "\e97e";
$marso-icon-SUV01: "\e97f";
$marso-icon-suv2: "\e980";
$marso-icon-test_winner: "\e981";
$marso-icon-timer: "\e982";
$marso-icon-toy: "\e983";
$marso-icon-trash: "\e984";
$marso-icon-truck: "\e985";
$marso-icon-tyre: "\e986";
$marso-icon-tyre_date: "\e987";
$marso-icon-tyre_insurance: "\e988";
$marso-icon-tyre_safe: "\e989";
$marso-icon-tyre_service: "\e98a";
$marso-icon-tyrestack: "\e98b";
$marso-icon-UHP: "\e98c";
$marso-icon-wheel: "\e98d";
$marso-icon-wifi: "\e98e";
$marso-icon-plus: "\f067";
$marso-icon-search1: "\f002";
$marso-icon-user: "\f007";
$marso-icon-check: "\f00c";
$marso-icon-close: "\f00d";
$marso-icon-remove: "\f00d";
$marso-icon-times: "\f00d";
$marso-icon-lock: "\f023";
$marso-icon-pencil: "\f040";
$marso-icon-chevron-left: "\f053";
$marso-icon-chevron-right: "\f054";
$marso-icon-plus-circle: "\f055";
$marso-icon-minus-circle: "\f056";
$marso-icon-times-circle: "\f057";
$marso-icon-check-circle: "\f058";
$marso-icon-info-circle: "\f05a";
$marso-icon-exclamation-circle: "\f06a";
$marso-icon-exclamation-triangle: "\f071";
$marso-icon-warning: "\f071";
$marso-icon-chevron-up: "\f077";
$marso-icon-chevron-down: "\f078";
$marso-icon-upload: "\f093";
$marso-icon-square-o: "\f096";
$marso-icon-twitter: "\f099";
$marso-icon-square: "\f0c8";
$marso-icon-caret-down: "\f0d7";
$marso-icon-caret-up: "\f0d8";
$marso-icon-caret-left: "\f0d9";
$marso-icon-caret-right: "\f0da";
$marso-icon-sort: "\f0dc";
$marso-icon-unsorted: "\f0dc";
$marso-icon-sort-desc: "\f0dd";
$marso-icon-sort-down: "\f0dd";
$marso-icon-sort-asc: "\f0de";
$marso-icon-sort-up: "\f0de";
$marso-icon-envelope: "\f0e0";
$marso-icon-angle-down: "\f107";
$marso-icon-circle-o: "\f10c";
$marso-icon-circle: "\f111";
$marso-icon-file-text: "\f15c";
$marso-icon-youtube: "\f167";
$marso-icon-youtube-play: "\f16a";


;@import "sass-embedded-legacy-load-done:3";