<template>
    <transition name="fade">
        <div 
            v-if="isOpen"
            class="modal"
        >
            <transition name="fade">
                <div
                    v-if="screenOrientationType === 'portrait-primary'"
                    class="overlay"
                >
                    <p>
                        {{ trans('turn_your_device_to_sign', 'Turn your device to sign!', 'signing_modal') }}
                    </p>
                    <div class="turn-icon">
                        <i class="marso-icon-refresh" />
                    </div>
                </div>
            </transition>
            <div
                class="modal-content"
                :class="screenOrientationType === 'portrait-primary' ? 'flex-col' : ''"
            >
            <div
                class="buttons"
                :class="{
                    '!flex-row-reverse': screenOrientationType === 'portrait-primary',
                    'z-[51]': isButtonsHighlighted
                }"
            >
                    <button
                        type="button"
                        class="bg-red p-4 rounded"
                        :class="screenOrientationType === 'portrait-primary' && !isButtonsHighlighted ? 'rotate-90' : ''"
                        @click="toggleModal"
                    >
                        <i class="marso-icon-wrong" />
                    </button>
                    <button
                        type="button"
                        class="bg-blue p-4 rounded"
                        :class="screenOrientationType === 'portrait-primary' && !isButtonsHighlighted ? 'rotate-90' : ''"
                        @click="refreshCanvas"
                    >
                        <i class="marso-icon-refresh" />
                    </button>
                    <button
                        type="button"
                        class="bg-green p-4 rounded"
                        :class="screenOrientationType === 'portrait-primary' && !isButtonsHighlighted ? 'rotate-90' : ''"
                        @click="sign"
                    >
                        <i class="marso-icon-right" />
                    </button>
                </div>
                <canvas
                    ref="canvas"
                    :width="canvasWidth"
                    :height="canvasHeight"
                    @mousedown="startDrawing"
                    @mousemove="draw"
                    @mouseup="endDrawing"
                    @touchstart="startDrawing"
                    @touchmove="draw"
                    @touchend="endDrawing"
                />
            </div>
        </div>
    </transition>
</template>

<script setup lang="ts">
import { ref, defineProps, defineEmits, Ref, onMounted, onUnmounted } from "vue";
import { trans } from "../common/i18n";
import { useToastStore } from "../stores/toast/toastStore";

const toastStore = useToastStore();

const screenOrientationType: Ref<string> = ref("");
const canvas: Ref<HTMLCanvasElement | null> = ref(null);
const windowInnerWidth: Ref<number> = ref(0);
const windowInnerHeight: Ref<number> = ref(0);
const canvasWidth: Ref<number> = ref(0);
const canvasHeight: Ref<number> = ref(0);
const canvasPercentage: Ref<number> = ref(0.8);
const isDrawing: Ref<boolean> = ref(false);
const lastX: Ref<number> = ref(0);
const lastY: Ref<number> = ref(0); 
const savedCanvasContent: Ref<string | null> = ref(null);
const firstOrientationIsPortrait: Ref<boolean> = ref(false);
const isButtonsHighlighted: Ref<boolean> = ref(false);

// eslint-disable-next-line no-unused-vars
const props = defineProps({
    isOpen: {
        type: Boolean,
        default: false
    },
    isButtonsHighlighted: {
        type: Boolean,
        default: false
    }
});

const emit = defineEmits(["toggle", "sign"]);

onMounted(() => {
    setScreenOrientationType();

    if (screenOrientationType.value === "portrait-primary") {
        firstOrientationIsPortrait.value = true;
    }

    window.screen.orientation.addEventListener('change', () => {
        setScreenOrientationType();
        updateCanvasSize();
    });

    windowInnerWidth.value = window.innerWidth;
    windowInnerHeight.value = window.innerHeight;
    
    canvasWidth.value = windowInnerWidth.value * canvasPercentage.value;
    canvasHeight.value = windowInnerHeight.value * canvasPercentage.value;
});

onUnmounted(() => {
        window.screen.orientation.addEventListener('change', () => {
        setScreenOrientationType();
        updateCanvasSize();
    });
})

const toggleModal = () => {
    emit("toggle");
};

const startDrawing = (event: MouseEvent | TouchEvent) => {
    isDrawing.value = true;
    if (!canvas.value) return;

    if (event instanceof MouseEvent) {
        [lastX.value, lastY.value] = [event.offsetX, event.offsetY];
    } else if (event instanceof TouchEvent) {
        const touch = event.touches[0];
        [lastX.value, lastY.value] = [
            touch.clientX - canvas.value.offsetLeft,
            touch.clientY - canvas.value.offsetTop,
        ];
    }
};

const draw = (event: MouseEvent | TouchEvent) => {
    if (!isDrawing.value || !canvas.value) return;
    event.preventDefault();
    const context = canvas.value.getContext("2d");
    if (!context) return;

    context.beginPath();
    context.moveTo(lastX.value, lastY.value);

    if (event instanceof TouchEvent) {
        const touch = event.touches[0];
        [lastX.value, lastY.value] = [
            touch.clientX - canvas.value.offsetLeft,
            touch.clientY - canvas.value.offsetTop,
        ];
    } else {
        const mouseEvent = event as MouseEvent;
        [lastX.value, lastY.value] = [mouseEvent.offsetX, mouseEvent.offsetY];
    }

    context.lineTo(lastX.value, lastY.value);
    context.stroke();
};

const endDrawing = () => {
    isDrawing.value = false;
};

const refreshCanvas = () => {
    if (!canvas.value) return;
    const context = canvas.value.getContext("2d");
    if (!context) return;
    const imageData = context.getImageData(0, 0, canvasWidth.value, canvasHeight.value);

    if (!isCanvasEmpty(imageData)) {
        context.clearRect(0, 0, canvasWidth.value, canvasHeight.value);

        highlightButtons();

        toastStore.addToast({
            title: trans('deleted_successfully', 'Deleted successfully!', 'signing_modal'),
            type: "success",
            showIcon: true,
        });
    } else {
        toastStore.addToast({
            title: trans('the_signature_field_is_empty', 'The signature field is empty!', 'signing_modal'),
            type: "danger",
            showIcon: true,
        });

        return;
    }
};

const isCanvasEmpty = (imageData: any) => {
    return !Array.from({ length: imageData.data.length / 4 }, (_, i) => i * 4).some(i => imageData.data[i + 3] !== 0);
}

const updateCanvasSize = () => {
    if (firstOrientationIsPortrait.value) {
        if (screenOrientationType.value === "portrait-primary") {
            canvasWidth.value = windowInnerWidth.value * canvasPercentage.value;
            canvasHeight.value = windowInnerHeight.value * canvasPercentage.value;
        } else {
            canvasWidth.value = windowInnerHeight.value * canvasPercentage.value;
            canvasHeight.value = windowInnerWidth.value * canvasPercentage.value;
        }
    } else {
        if (screenOrientationType.value === "landscape-primary") {
            canvasWidth.value = windowInnerWidth.value * canvasPercentage.value;
            canvasHeight.value = windowInnerHeight.value * canvasPercentage.value;
        } else {
            canvasWidth.value = windowInnerHeight.value * canvasPercentage.value;
            canvasHeight.value = windowInnerWidth.value * canvasPercentage.value;
        }
    }

    saveCanvasContent();
};

const saveCanvasContent = () => {
    if (savedCanvasContent.value && canvas.value) {
        const context = canvas.value.getContext("2d");
        if (context) {
            const img = new Image();

            img.onload = () => {
                context.clearRect(0, 0, canvasWidth.value, canvasHeight.value);
                context.save();

                if (screenOrientationType.value === "portrait-primary") {
                    context.translate(canvasWidth.value, 0);
                    context.rotate(Math.PI / 2);
                    context.imageSmoothingEnabled = false;
                    context.drawImage(img, 0, 0, canvasHeight.value, canvasWidth.value);
                } else {
                    context.translate(0, canvasHeight.value);
                    context.rotate(-Math.PI / 2);
                    context.imageSmoothingEnabled = false;
                    context.drawImage(img, 0, 0, canvasHeight.value, canvasWidth.value);
                }

                context.restore();
            };

            img.src = savedCanvasContent.value;
        }
    }
};

const highlightButtons = () => {
    if (!canvas.value) return;
    const context = canvas.value.getContext("2d");
    if (!context) return;
    const imageData = context.getImageData(0, 0, canvasWidth.value, canvasHeight.value);

    if (!isCanvasEmpty(imageData) && screenOrientationType.value === "portrait-primary") {
        isButtonsHighlighted.value = true;
    } else {
        isButtonsHighlighted.value = false;
    }
}

const rotateCanvas = (canvas: HTMLCanvasElement, degrees: number) => {
    const radians = degrees * Math.PI / 180;
    const tempCanvas = document.createElement("canvas");
    const tempContext = tempCanvas.getContext("2d");

    if (!tempContext) return;

    if (degrees % 180 === 90 || degrees % 180 === -90) {
        tempCanvas.width = canvas.height;
        tempCanvas.height = canvas.width;
    } else {
        tempCanvas.width = canvas.width;
        tempCanvas.height = canvas.height;
    }

    tempContext.translate(tempCanvas.width / 2, tempCanvas.height / 2);
    tempContext.rotate(radians);
    tempContext.drawImage(canvas, -canvas.width / 2, -canvas.height / 2);

    if (degrees % 180 === 90 || degrees % 180 === -90) {
        canvas.width = tempCanvas.width;
        canvas.height = tempCanvas.height;
    }

    const context = canvas.getContext("2d");
    if (!context) return;

    context.clearRect(0, 0, canvas.width, canvas.height);
    context.drawImage(tempCanvas, 0, 0);
};

const sign = () => {
    if (!canvas.value) return;
    const context = canvas.value.getContext("2d");
    if (!context) return;
    const imageData = context.getImageData(0, 0, canvasWidth.value, canvasHeight.value);

    if (!isCanvasEmpty(imageData)) {
        if (screenOrientationType.value === "portrait-primary") {
            rotateCanvas(canvas.value, -90);
            emit("sign", canvas.value.toDataURL());
        } else {
            emit("sign", canvas.value.toDataURL());
        }

        toggleModal();

        toastStore.addToast({
            title: trans('signed_successfully', 'Signed successfully!', 'signing_modal'),
            type: "success",
            showIcon: true,
        });

        context.clearRect(0, 0, canvasWidth.value, canvasHeight.value);
        
        highlightButtons();
    } else {
        toastStore.addToast({
            title: trans('the_signature_field_is_empty', 'The signature field is empty!', 'signing_modal'),
            type: "danger",
            showIcon: true,
        });

        return;
    }
}

const setScreenOrientationType = () => {
    screenOrientationType.value = window.screen.orientation.type

    highlightButtons();

    if (canvas.value) {
        savedCanvasContent.value = canvas.value.toDataURL();
    }
};
</script>

<style scoped>
.modal {
    @apply bg-gray;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    z-index: 50;
    transform-origin: center center;
    width: 100%;
    height: 100%;
}

.modal-content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    padding: 15px;
}

.buttons {
    @apply text-4xl text-white;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

canvas {
    @apply bg-white;
    border: 2px solid black;
}

.overlay {
    @apply inset-0 bg-black bg-opacity-60 text-white text-2xl text-center;
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 50;
}

.turn-icon {
    @apply text-6xl mt-4;
    animation: turn 1.5s linear infinite;
}

@keyframes turn {
    0%, 100% { transform: rotate(0deg); }
    50% { transform: rotate(90deg); }
}
</style>
