@import "/srv/www/assets/styles/_mixins.scss";

.custom-checkbox {
    input[type="checkbox"] ~ .checked, input[type="checkbox"]:checked ~ .not-checked {
        display: none;
    }

    input[type="checkbox"]:checked ~ .checked, input[type="checkbox"] ~ .not-checked {
        display: inline-block;
    }
}
